/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import GlobalStateProvider from "src/state/GlobalStateProvider";

export const wrapPageElement = ({element, props}) => {
  return (
    <GlobalStateProvider {...props}>
      {element}
    </GlobalStateProvider>
  )
};

export const onRouteUpdate = ({location, prevLocation}) => {
  if (typeof window !== "undefined") {
    let title = document.title;
    let referrer = prevLocation ? prevLocation.pathname : null;
    window.analytics && window.analytics.page(title, {
      referrer: referrer,
    });
  }
};

export const onClientEntry = () => {
}